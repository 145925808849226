* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  width: 100vw;
  height: 100vh !important;
  background-color: var(--neutral-20);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --purple-60: #8e43c0;
  --purple-40: #b489e5;
  --neutral-10: #fafaff;
  --neutral-20: #f1f1f9;
  --neutral-50: #9c9cc2;
  --neutral-60: #64648f;
  --neutral-70: #48486b;
  --green: #54df94;
  --error: #f57a82;
  --background: #300a48;
  --off-white: #ffffff;
}

.modal {
  width: 328px;
  /* height: 420px; */
  height: 495px;
  background-color: #ffffff;
  background-color: var(--off-white);
  border-radius: 20px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* position: relative; */
}
.content-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
}

.modal-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #64648f;
  color: var(--neutral-60);
  margin-bottom: 20px;
}

.modal-info {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #48486b;
  color: var(--neutral-70);
}

.button-container {
  margin-top: auto;
  width: 100%;
}

.primary-button {
  width: 100%;
  height: 50px;
  /* background-color: var(--purple-60); */
  color: #ffffff;
  color: var(--off-white);
  border-radius: 25px;
  margin-top: auto;
  border: none;
  font-weight: 500;
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
}
.cancel-button {
  margin: 10px 0;
  /* color: var(--purple-40); */
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.shadow {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.m-top {
  margin-top: 20px;
}
.m-bot {
  margin-bottom: 10px;
}
.m-vertical {
  margin-left: 5px;
  margin-right: 5px;
}
.relative {
  position: relative;
}
.back {
  position: absolute;
  left: 0;
  top: 6px;
}

.rigth {
  margin-left: auto;
  text-align: right;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.long-time {
  text-align: center;
}

.services-container {
  margin-top: 60px;
}
.service-card {
  width: 280px;
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin: 20px 0;
  transition: 0.3s;
  cursor: pointer;
}

.user-input-container {
  width: 235px;
  height: 50px;
  background-color: var(--neutral-20);
  border: 2px solid #e2e2f0;
  border-radius: 5px;
  margin: 30px auto 10px;
  padding: 0 5px;
}
.user-input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--neutral-60);
}

.user-input {
  border: none;
  background-color: transparent;
  height: 30px;
  width: 100%;
  font-family: 'Montserrat', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.user-input:focus-visible {
  outline: none;
}
.user-input:focus {
  outline: none;
}
.user-input:focus-visible > .user-input-container {
  border: 2px solid var(--purple-40);
}
.user-name {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--neutral-70);
  margin-top: 20px;
}

.no-results {
  text-align: center;
  color: var(--error);
  font-weight: 500;
  font-size: 12px;
}

.verify {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
}

.error-phone {
  color: #ff9900;
  justify-content: space-evenly;
}

.check-phone {
  color: #2dcf3d;
  justify-content: center;
}

.react-tel-input .form-control {
  font-size: 12px !important;
  background: none !important;
  border: none !important;
  width: 100% !important;
  height: 28px !important;
  padding-left: 40px !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

.loader-container {
  margin: auto auto 0;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}

.method-card {
  margin: 8px;
  width: 43%;
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--neutral-10);
  border-radius: 8px;
}

.methods-container {
  width: 100%;
  /* height: 405px; */
  display: flex;
  flex-wrap: wrap;
  /* align-content: center; */
  overflow: hidden;
  /* overflow-y: scroll; */
  justify-content: center;
}
/* .methods-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.methods-container::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 6px;
  background-color: #d0d0d0;
} */

.method-name {
  font-size: 14px;
  font-weight: 700;
  margin-top: 5px;
  color: var(--neutral-60);
}

.checkout-info-container {
  height: 40px;
  width: 85%;
  background-color: var(--neutral-20);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  cursor: pointer;
}
.checkout-info {
  font-weight: 700;
  font-size: 14px;
  color: var(--neutral-70);
}

.checkout-info-label {
  width: 80%;
  margin: 10px auto;
  color: var(--neutral-50);
  font-weight: 700;
  font-size: 12px;
}

.copy {
  position: absolute;
  right: 15px;
  top: 15px;
}

.pm {
  top: 8px;
}

.bancumbre-container {
  height: auto;
  margin: 2px auto;
  padding: 5px 0;
}

.calculator {
  margin: auto 0;
}

.calculator-container {
  width: 100%;
  height: 52px;
  margin: 5px auto 10px;
  /* background-color: white; */
}

.calculator-inputs-container {
  display: flex;
  justify-content: space-between;
}
.calculator-label {
  margin-top: 5px;
  margin-right: 8px;
  margin-left: 8px;
}

.calculator-input {
  height: 20px;
  width: 67px;
}

.info-marketing {
  margin: auto auto -100px;
  color: #a5a5a5;
  font-size: 14px;
}

.input-flex {
  display: flex;
  align-items: center;
}

.info-marketing {
  margin: auto auto -100px;
  color: #a5a5a5;
  font-size: 14px;
}

.input-flex {
  display: flex;
  align-items: center;
}

.paypal-button-number-1 {
  display: none !important;
}

.success-img {
  display: block;
  margin: auto;
}
.seuccess-amount {
  /* font-size: 18px; */
  font-weight: bold;
  color: var(--purple-40);
}


