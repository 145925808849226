.info-marketing {
  margin: auto auto -100px;
  color: #a5a5a5;
  font-size: 14px;
}

.input-flex {
  display: flex;
  align-items: center;
}
