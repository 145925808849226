.services-container {
  margin-top: 60px;
}
.service-card {
  width: 280px;
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin: 20px 0;
  transition: 0.3s;
  cursor: pointer;
}
