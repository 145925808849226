.calculator {
  margin: auto 0;
}

.calculator-container {
  width: 100%;
  height: 52px;
  margin: 5px auto 10px;
  /* background-color: white; */
}

.calculator-inputs-container {
  display: flex;
  justify-content: space-between;
}
.calculator-label {
  margin-top: 5px;
  margin-right: 8px;
  margin-left: 8px;
}

.calculator-input {
  height: 20px;
  width: 67px;
}
