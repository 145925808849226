.method-card {
  margin: 8px;
  width: 43%;
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--neutral-10);
  border-radius: 8px;
}

.methods-container {
  width: 100%;
  /* height: 405px; */
  display: flex;
  flex-wrap: wrap;
  /* align-content: center; */
  overflow: hidden;
  /* overflow-y: scroll; */
  justify-content: center;
}
/* .methods-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.methods-container::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 6px;
  background-color: #d0d0d0;
} */

.method-name {
  font-size: 14px;
  font-weight: 700;
  margin-top: 5px;
  color: var(--neutral-60);
}
