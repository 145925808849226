:root {
  --purple-60: #8e43c0;
  --purple-40: #b489e5;
  --neutral-10: #fafaff;
  --neutral-20: #f1f1f9;
  --neutral-50: #9c9cc2;
  --neutral-60: #64648f;
  --neutral-70: #48486b;
  --green: #54df94;
  --error: #f57a82;
  --background: #300a48;
  --off-white: #ffffff;
}

.modal {
  width: 328px;
  /* height: 420px; */
  height: 495px;
  background-color: var(--off-white);
  border-radius: 20px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* position: relative; */
}
.content-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
}

.modal-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--neutral-60);
  margin-bottom: 20px;
}

.modal-info {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--neutral-70);
}

.button-container {
  margin-top: auto;
  width: 100%;
}

.primary-button {
  width: 100%;
  height: 50px;
  /* background-color: var(--purple-60); */
  color: var(--off-white);
  border-radius: 25px;
  margin-top: auto;
  border: none;
  font-weight: 500;
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
}
.cancel-button {
  margin: 10px 0;
  /* color: var(--purple-40); */
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.shadow {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.m-top {
  margin-top: 20px;
}
.m-bot {
  margin-bottom: 10px;
}
.m-vertical {
  margin-left: 5px;
  margin-right: 5px;
}
.relative {
  position: relative;
}
.back {
  position: absolute;
  left: 0;
  top: 6px;
}

.rigth {
  margin-left: auto;
  text-align: right;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.long-time {
  text-align: center;
}
