.checkout-info-container {
  height: 40px;
  width: 85%;
  background-color: var(--neutral-20);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  cursor: pointer;
}
.checkout-info {
  font-weight: 700;
  font-size: 14px;
  color: var(--neutral-70);
}

.checkout-info-label {
  width: 80%;
  margin: 10px auto;
  color: var(--neutral-50);
  font-weight: 700;
  font-size: 12px;
}

.copy {
  position: absolute;
  right: 15px;
  top: 15px;
}

.pm {
  top: 8px;
}

.bancumbre-container {
  height: auto;
  margin: 2px auto;
  padding: 5px 0;
}
