.user-input-container {
  width: 235px;
  height: 50px;
  background-color: var(--neutral-20);
  border: 2px solid #e2e2f0;
  border-radius: 5px;
  margin: 30px auto 10px;
  padding: 0 5px;
}
.user-input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--neutral-60);
}

.user-input {
  border: none;
  background-color: transparent;
  height: 30px;
  width: 100%;
  font-family: 'Montserrat', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.user-input:focus-visible {
  outline: none;
}
.user-input:focus {
  outline: none;
}
.user-input:focus-visible > .user-input-container {
  border: 2px solid var(--purple-40);
}
.user-name {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--neutral-70);
  margin-top: 20px;
}

.no-results {
  text-align: center;
  color: var(--error);
  font-weight: 500;
  font-size: 12px;
}

.verify {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
}

.error-phone {
  color: #ff9900;
  justify-content: space-evenly;
}

.check-phone {
  color: #2dcf3d;
  justify-content: center;
}

.react-tel-input .form-control {
  font-size: 12px !important;
  background: none !important;
  border: none !important;
  width: 100% !important;
  height: 28px !important;
  padding-left: 40px !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}
